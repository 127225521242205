@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --background: #f1f1f1;
    --foreground: #010101;
}

html, body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Geologica', 'Charm', 'Arial', 'Helvetica', 'sans-serif';
}

.font-geologica {
  font-family: 'Geologica', sans-serif !important; /* Ensure specificity */
}

/* loading screen */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.animate-pulse-1 {
  animation: pulse 0.4s infinite;
}

/* menu button */
  /* burger to arrow */
.hamburger-label:hover .line1 {
  transform: rotate(45deg) translate(1.05rem, -0.55rem) scaleX(0.75);
}

.hamburger-label:hover .line3 {
  transform: rotate(-45deg) translate(1.05rem, 0.55rem) scaleX(0.75);
}

.hamburger-label:hover .line1-small {
  transform: rotate(45deg) translate(0.78rem, -0.45rem) scaleX(0.55);
}

.hamburger-label:hover .line3-small {
  transform: rotate(-45deg) translate(0.78rem, 0.45rem) scaleX(0.55);
}


  /* menu letter animation */
.ml6 {
  position: relative;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml6 .letter {
  display: inline-block;
  line-height: 1em;
}

/* dropdown */
  /* text animation */
.ml11 {

}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.5em;
  padding-bottom: 0.15em;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.ml11 .line1 { 
  top: 0; 
  left: 0;
}

.ml11 .letter {
  display: inline-block;
  line-height: 1em;
}

/* dropdown animation */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.slide-down {
  animation: slideDown 0.7s ease-out forwards;
}

.slide-up {
  animation: slideUp 0.7s ease-out forwards;
}

/* techincal skills Section */
.scramble-text {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #fafafa;
  transition: color 0.3s ease;
}

.dud {
  color: #757575;
}



/* Project Section */
.project-item {
  position: relative;
  overflow: hidden;
}

.relative.z-10 {
  position: relative;
  z-index: 10;
}

.collapse-content a {
  position: relative;
  z-index: 50;
}


.arrow-right {
  transform: translateX(0);
  opacity: 1;
  transition: transform 1.2s ease, opacity 1.2s ease;
}

.arrow-down {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.1s ease, opacity 0.1s ease;
}

.group:hover .arrow-right {
  transform: translateX(50%);
  opacity: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.group:hover .arrow-down {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.arrow {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.group:hover .arrow {
  transform: translateX(0);
  opacity: 1;
}
